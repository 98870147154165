import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AgGridReact, Box, Button, Typography } from '@og-pro/ui';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { loadContractAuditLogs } from '../../../../../actions/contracts';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { formatAuditLogs } from './helpers';

export const ContractAudit = () => {
    const dispatch = useDispatch();
    const { contractId } = useParams();
    const { data, isFetching, refetch } = useQuery({
        queryKey: ['contractAuditLogs', contractId],
        queryFn: () => dispatch(loadContractAuditLogs(contractId)),
        refetchOnWindowFocus: false,
    });

    const formattedLogs = useMemo(() => {
        if (data) {
            return formatAuditLogs(data.records);
        }

        return data;
    }, [data]);

    const columns = useMemo(() => {
        return [
            {
                // group cell renderer needed for expand / collapse icons
                cellRenderer: 'agGroupCellRenderer',
                flex: 0.5,
                headerName: 'User',
                field: 'user',
                sortable: true,
            },
            {
                headerName: 'Date updated',
                field: 'createdAt',
                sort: 'desc',
                sortable: true,
            },
            {
                headerName: 'Category',
                field: 'namespace',
                sortable: true,
            },
            {
                headerName: 'Change',
                field: 'action',
                sortable: true,
            },
            {
                flex: 1,
                headerName: 'Contract information',
                field: 'information',
            },
            {
                cellRenderer: (params) => params.value,
                flex: 1,
                headerName: 'Original',
                field: 'previousValue',
            },
            {
                cellRenderer: (params) => params.value,
                flex: 1,
                headerName: 'New',
                field: 'value',
            },
        ];
    }, []);

    if (isFetching) {
        return <LoadingSpinner text="Loading..." useOpenGovStyle />;
    }

    return (
        <Box>
            <Box display="flex" sx={{ mb: 3 }}>
                <Box flex={1}>
                    <Typography variant="h2">Contract Audit Log</Typography>
                </Box>
                <Box alignSelf="flex-end" sx={{ textAlign: 'right' }}>
                    <Button onClick={refetch} variant="outlined">
                        Refresh
                    </Button>
                </Box>
            </Box>

            {!!data && (
                <Box>
                    <AgGridReact
                        columnDefs={columns}
                        defaultColDef={{
                            sortable: false,
                            resizable: false,
                            suppressHeaderMenuButton: true,
                            wrapText: true,
                            autoHeight: true,
                        }}
                        detailCellRenderer={({ data: audit }) => {
                            return (
                                <Box key={audit.originalLog.uuid} sx={{ p: 2 }}>
                                    <Typography variant="bodySmall">
                                        {audit.originalLog.createdAt}
                                    </Typography>
                                    <Typography variant="bodySmall">
                                        {audit.originalLog.user.firstName}{' '}
                                        {audit.originalLog.user.lastName},{' '}
                                        {audit.originalLog.user.email}
                                    </Typography>
                                    <Typography variant="h6">
                                        {audit.originalLog.subtype}
                                    </Typography>
                                    <Box display="flex" gap={1}>
                                        <Box flex={1}>
                                            <pre>
                                                {JSON.stringify(
                                                    audit.originalLog.previousValue,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                        </Box>
                                        <Box flex={1}>
                                            <pre>
                                                {JSON.stringify(audit.originalLog.value, null, 2)}
                                            </pre>
                                            <pre>
                                                {JSON.stringify(audit.originalLog.context, null, 2)}
                                            </pre>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        }}
                        detailRowAutoHeight
                        domLayout="autoHeight"
                        getRowHeight={(params) => {
                            const valueBreaks = String(params.data.value).split('\n').length || 0;
                            const previousValueBreaks =
                                String(params.data.previousValue).split('\n').length || 0;
                            const breaks =
                                valueBreaks > previousValueBreaks
                                    ? valueBreaks
                                    : previousValueBreaks;

                            if (breaks > 1) {
                                return 26 * breaks;
                            }

                            return 52;
                        }}
                        masterDetail
                        pagination
                        paginationPageSize={100}
                        paginationPageSizeSelector={false}
                        rowData={formattedLogs}
                    />
                </Box>
            )}
        </Box>
    );
};
