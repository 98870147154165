import React, { useContext } from 'react';
import { Field } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';
import classNames from 'classnames';

import { fieldNames } from '../../../../../forms/TemplateForm/constants';
import { TemplateEditV2FunctionsContext } from '../../../../context';
import { Toggle } from '../../../../../../../../components/Toggle/Toggle';

const { USE_EXTERNAL_DOCUMENT } = fieldNames;

export function TemplateEditV2DocumentBuilderSettingsForm() {
    const { disabled } = useContext(TemplateEditV2FunctionsContext);
    const styles = require('../index.scss');
    return (
        <Box className={styles.container}>
            <Box className={classNames(styles.oldFont, styles.section)}>
                <Typography variant="h3">Upload Settings</Typography>
                <Typography fontWeight={500}>Do you want to upload your own document?</Typography>
                <Typography color="secondary" mb={1} mt={0.5} variant="bodySmall">
                    If you choose to upload your own document, assembling with the Document Builder
                    will no longer be available.
                </Typography>
                <Field
                    aria-label="Upload your own document Switch"
                    component={Toggle}
                    disabled={disabled}
                    leftLabel="NO"
                    name={USE_EXTERNAL_DOCUMENT}
                    qaTag="templateEditor-toggleUseExternalDocument"
                    rightLabel="YES"
                    useOpenGovStyle
                />
            </Box>
        </Box>
    );
}
