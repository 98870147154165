import moment from 'moment';
import { SUBTYPES, CONTRACT_ASSOCIATIONS } from '@og-pro/shared-config/audits/record-audit';

import { ACTIONS, BOOLEANS, DATES, IGNORED_FIELDS, ASSOCIATIONS } from './constant';

const formatValue = (key, value) => {
    if (value === null || value === undefined) {
        return '--';
    }

    if (DATES.includes(key)) {
        return moment(value).format('ll');
    }

    if (BOOLEANS.includes(key)) {
        return value ? 'Yes' : 'No';
    }

    return value;
};

const getContextualInformation = ({ namespace, log }) => {
    switch (namespace) {
        case CONTRACT_ASSOCIATIONS.MILESTONE:
            return log.context?.milestone?.name || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.MILESTONE];
        case CONTRACT_ASSOCIATIONS.INSURANCE:
            return log.context?.tag?.name || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.INSURANCE];
        case CONTRACT_ASSOCIATIONS.CONTACT:
            return log.context?.tag?.name || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.CONTACT];
        case CONTRACT_ASSOCIATIONS.ATTACHMENT:
            return log.context?.filename || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.ATTACHMENT];
        case CONTRACT_ASSOCIATIONS.CHECKLIST:
            return log.context?.template?.title || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.CHECKLIST];
        case CONTRACT_ASSOCIATIONS.CHECKLIST_QUESTIONNAIRE:
            return `${log.context?.checklist?.template?.title || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.CHECKLIST]} / ${log.context?.title || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.CHECKLIST_QUESTIONNAIRE]}`;
        case CONTRACT_ASSOCIATIONS.NOTIFICATION:
            return log.context?.milestone?.name || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.MILESTONE];
        case CONTRACT_ASSOCIATIONS.REVIEW:
            return 'Vendor Review';
        case CONTRACT_ASSOCIATIONS.REVIEW_COMPLAINT:
            return `Vendor Complaint / ${log.context?.complaint?.title || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.REVIEW]}`;
        case CONTRACT_ASSOCIATIONS.PURCHASE_ORDER:
            return `Purchase Order ${log.context?.purchaseOrder?.number || ''}`;
        case CONTRACT_ASSOCIATIONS.RENEWAL:
            return `Renewal option`;
        default:
            return namespace;
    }
};

const getInformation = ({ namespace, key, log }) => {
    const formattedKey = `[${key}]`;

    if (!namespace) {
        return formattedKey;
    }

    if (namespace === CONTRACT_ASSOCIATIONS.INSURANCE && key === 'tag_id') {
        return 'Insurance type';
    }

    if (namespace === CONTRACT_ASSOCIATIONS.CONTACT && key === 'tag_id') {
        return 'Contact type';
    }

    if (namespace === CONTRACT_ASSOCIATIONS.ATTACHMENT && key === 'filename') {
        return 'File name';
    }

    if (namespace === CONTRACT_ASSOCIATIONS.CHECKLIST_QUESTIONNAIRE && key === 'title') {
        return `${log.context?.checklist?.template?.title} / Question title`;
    }

    if (namespace === CONTRACT_ASSOCIATIONS.SUBSCRIBER) {
        return 'Who to notify';
    }

    if (namespace === CONTRACT_ASSOCIATIONS.REVIEW) {
        return `Vendor Review / ${formattedKey}`;
    }

    return `${getContextualInformation({ namespace, log })} / ${formattedKey}`;
};

export const formatAuditLogs = (rawLogs) => {
    return rawLogs.reduce((acc, log) => {
        const subtypeParts = log.subtype.split('.');
        const action = subtypeParts.pop();
        const namespace = subtypeParts.join('.');

        const commonProps = {
            user: `${log.user.firstName} ${log.user.lastName}`,
            action: ACTIONS[action],
            namespace: ASSOCIATIONS[namespace] || 'Contract Record Details',
            createdAt: moment.unix(log.createdAt / 1000).format('MM/DD/YYYY HH:mm:ss'),
            originalLog: log,
        };

        if (namespace === CONTRACT_ASSOCIATIONS.SUBSCRIBER) {
            const value = `${log.value.user?.firstName} ${log.value.user?.lastName}`;

            return acc.concat({
                ...commonProps,
                information: getInformation({ namespace, key: '', log }),
                value: action === SUBTYPES.CREATE ? value : '--',
                previousValue: action === SUBTYPES.DELETE ? value : '--',
            });
        }

        if (namespace === CONTRACT_ASSOCIATIONS.NOTIFICATION) {
            let value =
                action === SUBTYPES.CREATE
                    ? `${log.value.offset} ${log.value.offsetUnit} before (${log.context.milestone.name} on ${moment(log.value.dateOfEvent).format('MM/DD/YYYY')})`
                    : null;
            let previousValue =
                action === SUBTYPES.DELETE
                    ? `${log.value.offset} ${log.value.offsetUnit} before (${log.context.milestone.name} on ${moment(log.value.dateOfEvent).format('MM/DD/YYYY')})`
                    : null;

            if (action === SUBTYPES.UPDATE) {
                value = `${log.context.notification?.offset} ${log.context.notification?.offsetUnit} before (${log.context.milestone.name} on ${moment(log.context.notification?.dateOfEvent).format('MM/DD/YYYY')})`;
                previousValue = `${log.context.previousNotification?.offset} ${log.context.previousNotification?.offsetUnit} before (${log.context.milestone.name} on ${moment(log.context.previousNotification?.dateOfEvent).format('MM/DD/YYYY')})`;
            }

            return acc.concat({
                ...commonProps,
                information: getContextualInformation({ namespace, log }),
                value,
                previousValue,
            });
        }

        if (action === SUBTYPES.DELETE || action === SUBTYPES.CREATE) {
            const stringifiedValues = Object.keys(log.value || {})
                .reduce((values, key) => {
                    if (
                        IGNORED_FIELDS.includes(key) ||
                        IGNORED_FIELDS.includes(`${namespace}.${key}`)
                    ) {
                        return values;
                    }

                    return values.concat([`[${key}]: ${formatValue(key, log.value[key])}`]);
                }, [])
                .join('\n');

            return acc.concat({
                ...commonProps,
                information: getContextualInformation({ namespace, log }),
                value: action === SUBTYPES.CREATE ? stringifiedValues : '--',
                previousValue: action === SUBTYPES.DELETE ? stringifiedValues : '--',
            });
        }

        return acc.concat(
            Object.keys(log.value || {}).reduce((parsedLogs, key) => {
                if (
                    IGNORED_FIELDS.includes(key) ||
                    IGNORED_FIELDS.includes(`${namespace}.${key}`)
                ) {
                    return parsedLogs;
                }

                return parsedLogs.concat([
                    {
                        ...commonProps,
                        information: getInformation({ namespace, key, log }),
                        value: formatValue(key, log.value[key]),
                        previousValue: log.previousValue
                            ? formatValue(key, log.previousValue[key])
                            : '--',
                    },
                ]);
            }, [])
        );
    }, []);
};
