import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Box, Button, Dialog, ReduxFormToggle, ReduxFormRadioGroup, Typography } from '@og-pro/ui';

import { COMPILE_SETTINGS_FIELDS } from '../../constants';

const { ADD_PAGE_NUMBERS, INCLUDE_FIRST_PAGE, PAGE_NUMBERS_ALIGNMENT } = COMPILE_SETTINGS_FIELDS;

export const PageNumbersSettingsForm = reduxForm({
    form: 'pageNumbersSettingsForm',
})(({ handleSubmit, onCancel }) => {
    const addPageNumber = useSelector((state) =>
        formValueSelector('pageNumbersSettingsForm')(state, ADD_PAGE_NUMBERS)
    );

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ pt: 1, '& fieldset': { padding: 0, marginLeft: -1.5 } }}
        >
            <Typography variant="h4">Page Numbers</Typography>
            <Field
                component={ReduxFormToggle}
                labelId="Page Numbers"
                leftLabel=""
                name={ADD_PAGE_NUMBERS}
                qaTag='"pageNumbersSettings-addPageNumbers'
                rightLabel="Include Page Numbers for the Full Packet"
            />
            {addPageNumber && (
                <>
                    <Field
                        component={ReduxFormToggle}
                        labelId="Include First Page"
                        leftLabel=""
                        name={INCLUDE_FIRST_PAGE}
                        qaTag='"pageNumbersSettings-includeFirstPage'
                        rightLabel="Include Page Numbers on the first page"
                    />
                    <Field
                        component={ReduxFormRadioGroup}
                        description="Page numbers will appear at the bottom of the page."
                        formControlProps={{
                            sx: {
                                mt: 1,
                            },
                        }}
                        label="Location"
                        name={PAGE_NUMBERS_ALIGNMENT}
                        options={[
                            { value: 'left', label: 'Left' },
                            { value: 'center', label: 'Center' },
                            { value: 'right', label: 'Right' },
                        ]}
                        qaTag="pageNumbersSettings-endsInPurchaseOrder"
                        row
                    />
                </>
            )}
            <Box alignItems="center" display="flex" justifyContent="flex-end" sx={{ pt: 3 }}>
                <Box mr={1}>
                    <Button
                        color="secondary"
                        onClick={onCancel}
                        qaTag="pageNumberSetting-cancel"
                        type="button"
                        variant="text"
                    >
                        Cancel
                    </Button>
                </Box>
                <Box>
                    <Button
                        color="primary"
                        qaTag="pageNumberSetting-submit"
                        type="submit"
                        variant="contained"
                    >
                        Apply
                    </Button>
                </Box>
            </Box>
        </Box>
    );
});

export const PageNumbersSettings = ({ onSubmit, settings }) => {
    const [open, setOpen] = useState(false);

    const onPreSubmit = (values) => {
        onSubmit(values);
        setOpen(false);
    };

    return (
        <>
            <Button
                color="secondary"
                onClick={() => setOpen(true)}
                qaTag="projectEditor-pageNumberSettings"
                size="small"
                variant="outlined"
            >
                Page Numbers: {settings.addPageNumbers ? 'ON' : 'OFF'}
            </Button>
            <Dialog
                dialogTitle="Manage Page Numbers"
                disableScrollLock
                fullWidth
                maxWidth="sm"
                onClose={() => setOpen(false)}
                open={open}
            >
                <Box>
                    <PageNumbersSettingsForm
                        initialValues={{ [PAGE_NUMBERS_ALIGNMENT]: 'right', ...settings }}
                        onCancel={() => setOpen(false)}
                        onSubmit={onPreSubmit}
                    />
                </Box>
            </Dialog>
        </>
    );
};

PageNumbersSettings.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        addPageNumbers: PropTypes.bool,
    }).isRequired,
};
