import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { recordDocumentTypesDict } from '@og-pro/shared-config/recordsRetention';

import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { Dropdown, LoadingButton, MenuItem } from '../../../../components';
import { deleteExpired, deleteProjects } from '../../../../actions/projects';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    INTAKES_FILTER,
    DOCUMENTS_FILTER,
    CONTRACTS_FILTER,
    CONTRACTS_WITH_RENEWALS_FILTER,
    CONTRACTS_WITHOUT_RENEWALS_FILTER,
    CONTRACT_DOCUMENTS_FILTER,
    EXPIRED,
    filterLabels,
} from '../constants';

const { CONTRACTS, DOCUMENTS, INTAKES } = recordDocumentTypesDict;

export const RetentionActions = ({
    disableApplyPolicies,
    filter,
    handleApplyPolicies,
    handleSelectPolicy,
    loading,
    location,
    policies,
    selectedRows,
    setFilter,
    showApplyPolicies,
    showDelete,
    showPolicies,
    storeLocation,
}) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');
    const isDocumentCleanUp = location && location.pathname.includes('documents');
    const showEnhancements = useFlags(FLAGS.RECORDS_RETENTION_ENHANCEMENTS);

    const handleDeleteSelectedRecords = () => {
        let onConfirm;
        if (storeLocation === EXPIRED) {
            const contracts = selectedRows.filter((item) => item.recordType === CONTRACTS);
            const projects = selectedRows.filter(
                (item) => item.recordType === DOCUMENTS || item.recordType === INTAKES
            );
            onConfirm = () =>
                dispatch(
                    deleteExpired({
                        contractIds: contracts.map((contract) => contract.id),
                        projectIds: projects.map((project) => project.id),
                    })
                );
        } else {
            const projectIds = selectedRows.map((item) => item.id);
            onConfirm = () =>
                dispatch(
                    deleteProjects({
                        projectIds,
                    })
                );
        }
        dispatch(
            showConfirmationSimpleModal(onConfirm, {
                bsStyle: 'danger',
                btnText: 'Delete Records',
                text: `Are you sure you want to delete ${selectedRows.length} records?`,
                title: 'Confirmation Required',
            })
        );
    };

    const renderApplyPolicies = () => {
        return (
            <div className={styles.policies}>
                <LoadingButton
                    bsStyle={disableApplyPolicies ? undefined : 'primary'}
                    className={styles.applyButton}
                    disabled={disableApplyPolicies}
                    loading={loading}
                    loadingText="Applying Policies"
                    onClick={handleApplyPolicies}
                    qaTag="retentionActions-applyPolicies"
                    text="Apply Policy"
                />
            </div>
        );
    };

    // If user is on the document clean up page, show 'Projects' instead of 'All Projects'
    const getActiveFilterLabel = () => {
        if (filter === DOCUMENTS_FILTER && isDocumentCleanUp && showEnhancements) {
            return 'Projects';
        }
        return (showEnhancements ? filterLabels[filter] : filter) || 'All';
    };

    const getProjectFilterName = () => {
        if (showEnhancements) {
            return isDocumentCleanUp ? 'Projects' : 'All Projects';
        }
        return 'Documents';
    };

    const renderFilterSection = () => (
        <div className={styles.filter}>
            <span className={styles.title}>
                Filter by Record Type&nbsp;
                <i className="fa fa-filter" />
            </span>
            <Dropdown id="recordsRetention-filter" qaTag="recordsRetention-filter">
                <Dropdown.Toggle className={styles.dropdownToggle}>
                    {getActiveFilterLabel()}&nbsp;
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <MenuItem
                        active={filter === null}
                        key="all"
                        onClick={() => setFilter(null)}
                        qaTag="retentionActions-all"
                    >
                        All
                    </MenuItem>
                    <MenuItem
                        active={filter === DOCUMENTS_FILTER}
                        key="document"
                        onClick={() => setFilter(DOCUMENTS_FILTER)}
                        qaTag="retentionActions-document"
                    >
                        {getProjectFilterName()}
                    </MenuItem>
                    {isDocumentCleanUp && (
                        <MenuItem
                            active={filter === CONTRACT_DOCUMENTS_FILTER}
                            key="contractDocument"
                            onClick={() => setFilter(CONTRACT_DOCUMENTS_FILTER)}
                            qaTag="retentionActions-contractDocument"
                        >
                            Contract Documents
                        </MenuItem>
                    )}
                    {isDocumentCleanUp && (
                        <MenuItem
                            active={filter === INTAKES_FILTER}
                            key="intake"
                            onClick={() => setFilter(INTAKES_FILTER)}
                            qaTag="retentionActions-intake"
                        >
                            Intakes
                        </MenuItem>
                    )}
                    {!isDocumentCleanUp && (
                        <MenuItem
                            active={filter === CONTRACTS_FILTER}
                            key="contract"
                            onClick={() => setFilter(CONTRACTS_FILTER)}
                            qaTag="retentionActions-contracts"
                        >
                            {showEnhancements ? 'All Contract Records' : 'Contracts'}
                        </MenuItem>
                    )}
                    {!isDocumentCleanUp && showEnhancements && (
                        <MenuItem
                            active={filter === CONTRACTS_WITH_RENEWALS_FILTER}
                            key="contractWithRenewals"
                            onClick={() => setFilter(CONTRACTS_WITH_RENEWALS_FILTER)}
                            qaTag="retentionActions-contracts-with-renewals"
                        >
                            Contract records with available renewals
                        </MenuItem>
                    )}
                    {!isDocumentCleanUp && showEnhancements && (
                        <MenuItem
                            active={filter === CONTRACTS_WITHOUT_RENEWALS_FILTER}
                            key="contractWithoutRenewals"
                            onClick={() => setFilter(CONTRACTS_WITHOUT_RENEWALS_FILTER)}
                            qaTag="retentionActions-contracts-without-renewals"
                        >
                            Contract records with no available renewals
                        </MenuItem>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

    const renderPolicyOptions = () => {
        if (policies.length === 0) {
            return (
                <MenuItem disabled>
                    No policies available yet. To create one, go to <i className="fa fa-gear" />{' '}
                    Manage Policies.
                </MenuItem>
            );
        }

        return policies.map((policy) => (
            <MenuItem eventKey={policy.id} key={policy.id} qaTag={`retentionActions-${policy.id}`}>
                <span className={styles.policyAbbreviation}>{policy.abbreviation}:</span>{' '}
                {policy.title}
            </MenuItem>
        ));
    };

    const renderPolicies = () => (
        <div className={styles.policies}>
            <div className={styles.filter}>
                <span className={styles.title}>Set Policy for All Selected:</span>
                <Dropdown
                    className={styles.policyDropdown}
                    id="recordsRetention-policies"
                    onSelect={handleSelectPolicy}
                    qaTag="recordsRetention-policies"
                >
                    <Dropdown.Toggle>Select Policy Code&nbsp;</Dropdown.Toggle>
                    <Dropdown.Menu>{renderPolicyOptions()}</Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );

    const renderDeletion = () => {
        const disabled = !selectedRows || selectedRows.length < 1;
        return (
            <div className={styles.policies}>
                <LoadingButton
                    bsStyle={disabled ? undefined : 'danger'}
                    className={styles.applyButton}
                    disabled={disabled}
                    loading={loading}
                    loadingText="Deleting Selected Records"
                    onClick={handleDeleteSelectedRecords}
                    qaTag="retentionActions-deleteSelectedRecords"
                    text="Delete Selected Records"
                />
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.leftSection}>
                    {renderFilterSection()}
                    {showPolicies && renderPolicies()}
                </div>
                {showDelete && renderDeletion()}
                {showApplyPolicies && renderApplyPolicies()}
            </div>
        </div>
    );
};

RetentionActions.propTypes = {
    disableApplyPolicies: PropTypes.bool,
    filter: PropTypes.string,
    handleApplyPolicies: PropTypes.func,
    handleSelectPolicy: PropTypes.func,
    loading: PropTypes.bool,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    policies: PropTypes.array,
    selectedRows: PropTypes.array,
    setFilter: PropTypes.func,
    showApplyPolicies: PropTypes.bool,
    showDelete: PropTypes.bool,
    showPolicies: PropTypes.bool,
    storeLocation: PropTypes.string,
};
